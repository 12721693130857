header {
  width: 100%;
  background-color: black;
  color: white;
  font-size: 30px;
  font-weight: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

button {
  background-color: #333;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 3px;
  min-width: 80px;
}

.page {
  width: 90%;
  margin: auto;
}

#root {
  background-color: #f5f5f5;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #222;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.left-button {
  font-size: 13px;
  font-weight: normal;
  margin-right: 10px;
  cursor: pointer;
}

.menu {
  display: flex;
  align-content: center;
}

.menu a {
  text-decoration: none;
  color: white;
}